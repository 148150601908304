// Sidebar light

.app-sidebar {
  &--light {
    .sidebar-menu-collapsed {
      li {

        a {
          background: transparent;
          color: rgba($primary, .5);

          svg {
            stroke: rgba($primary, .6);
            fill: rgba($primary, .08);
          }

          &.active,
          &:hover {
            background: rgba($primary, .1);
            color: rgba($primary, 1);

            svg {
              stroke: rgba($primary, 1);
              fill: rgba($primary, .1);
            }
          }
        }
      }
    }
  }
}
