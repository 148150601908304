// Variables

// General

$sidebar-width:             210px;
$app-sidebar-transition:    cubic-bezier(0.685, 0.0473, 0.346, 1);
// Collapsed

$sidebar-width-collapsed:   100px;

// Light color scheme

$sidebar-bg-light:          $white;
$sidebar-shadow-light:      0px 0px 28px 0px rgba($primary, 0.08);
$sidebar-content-bg-light:  transparent;
// Dark color scheme

$sidebar-bg:                #253053;
$sidebar-shadow:            $box-shadow-layout-right;
$sidebar-content-bg:        transparent;
